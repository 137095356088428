$(document).ready(function() {
  $('html').on('click', function(event) {
    var navLink = $(event.target).find('a.dropdown_link')
	 	dropdown = $(event.target).find('a.dropdown_link').parents('.main_nav').find('[data-dropdown="' + $(event.target).find('a.dropdown_link').data('dropdown-rel') + '"]');
    
    var mobileLink = $('.mobile_nav.dropdown_link')
    	mobileDropdown = $('.dropdown_container[data-dropdown="menu"]');
    
    
    if( navLink.hasClass('opened') && !dropdown.is(':visible')) {
        navLink.toggleClass('active_link');
        dropdown.toggle();
        navLink.removeClass('opened');
    }
    
    if( mobileLink.hasClass('mobile-nav-opened') && !mobileDropdown.is(':visible') ) {
    	$('body').toggleClass('is-active');
      	$('.mobile_nav').find('div').toggleClass('open');
      	mobileDropdown.toggle();
      	mobileLink.removeClass('mobile-nav-opened');
    } 
    
    if( navLink.parents('.sticky_nav--stick').length > 0 ) {
      navLink.toggleClass('active_link');
      dropdown.toggle();
    }

  });
  
  $('div.product-weighted-blanket .product-video-wrapper img').on('click', function() {
    setTimeout(addControls, 500);
    
    function addControls() {
      $('product-video-wrapper').find('video').attr('controls',true);
    }
  	
  })
  
})